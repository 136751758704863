<template>
  <studio-card id="preview-studio" :edit="true" @reload="fetchStudio">
    <template slot-scope="{}">
      <v-stepper v-model="e1">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <h4 class="primary--text text-decoration-underline">
                {{ $t("studios.preview.bg_image") }}
              </h4>
              <FileUpload
                input-id="bg_file"
                class="pointer"
                :multiple="false"
                :drop="true"
                :drop-directory="false"
                accept="image/*"
                v-model="bg_file"
                @input-file="inputBGFile"
              >
                <div>
                  {{
                    $t(
                      $vuetify.breakpoint.xs
                        ? "studios.preview.click_image"
                        : "studios.preview.drop_image"
                    )
                  }}
                </div>
              </FileUpload>
              <h4 class="primary--text text-decoration-underline">
                {{ $t("studios.preview.center_image") }}
              </h4>
              <FileUpload
                input-id="center_file"
                class="pointer"
                :multiple="false"
                :drop="false"
                :drop-directory="false"
                accept="image/*"
                v-model="center_file"
                @input-file="inputCenterFile"
              >
                <div>
                  {{
                    $t(
                      $vuetify.breakpoint.xs
                        ? "studios.preview.click_image"
                        : "studios.preview.drop_image"
                    )
                  }}
                </div>
              </FileUpload>
            </v-col>

            <v-col cols="12" md="6">
              <h4 class="primary--text text-decoration-underline">
                {{ $t("studios.preview.center_image_mark") }}
              </h4>
              <v-row>
                <v-col cols="12" md="4" class="py-0">
                  <v-card
                    class="mt-2"
                    v-bind:class="[{ selected: center_image_mark === '0px' }]"
                    @click="center_image_mark = '0px'"
                  >
                    <v-card-title>{{
                      $t("studios.preview.mark.no_mark")
                    }}</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <img :src="center_image" width="100%" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-card
                    class="mt-2"
                    v-bind:class="[{ selected: center_image_mark === '10px' }]"
                    @click="center_image_mark = '10px'"
                  >
                    <v-card-title>{{
                      $t("studios.preview.mark.simple")
                    }}</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <img
                            :src="center_image"
                            width="100%"
                            style="border-radius: 10px"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-card
                    class="mt-2"
                    v-bind:class="[{ selected: center_image_mark === '50%' }]"
                    @click="center_image_mark = '50%'"
                  >
                    <v-card-title>{{
                      $t("studios.preview.mark.circle")
                    }}</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <img
                            :src="center_image"
                            width="100%"
                            style="border-radius: 50%"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <h4 class="primary--text text-decoration-underline">
                {{ $t("studios.preview.center_image_align") }}
              </h4>
              <v-row>
                <v-col class="py-0" cols="4" md="3">
                  <v-card
                    class="mt-2"
                    v-bind:class="[
                      { selected: center_image_align === 'start' },
                    ]"
                    @click="center_image_align = 'start'"
                  >
                    <v-card-title>{{ $t("left") }}</v-card-title>
                    <v-icon x-large class="text-center d-block"
                      >mdi-align-horizontal-left</v-icon
                    >
                  </v-card>
                </v-col>
                <v-col class="py-0" cols="4" md="3">
                  <v-card
                    class="mt-2"
                    v-bind:class="[
                      { selected: center_image_align === 'center' },
                    ]"
                    @click="center_image_align = 'center'"
                  >
                    <v-card-title>{{ $t("center") }}</v-card-title>
                    <v-icon x-large class="text-center d-block"
                      >mdi-align-horizontal-center</v-icon
                    >
                  </v-card>
                </v-col>
                <v-col class="py-0" cols="4" md="3">
                  <v-card
                    class="mt-2"
                    v-bind:class="[{ selected: center_image_align === 'end' }]"
                    @click="center_image_align = 'end'"
                  >
                    <v-card-title>{{ $t("right") }}</v-card-title>
                    <v-icon x-large class="text-center d-block"
                      >mdi-align-horizontal-right</v-icon
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              style="
                width: 100px !important;
                color: #363533;
                height: 30px;
                padding-top: 10px !important;
              "
              elevation="0"
              @click="update"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: "" }) }}
            </v-btn>
          </v-row>
        </v-container>
      </v-stepper>
    </template>
  </studio-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "PreviewCard",
  components: {
    StudioCard: () => import("@/components/inscription/studio/Card"),
    FileUpload: () => import("vue-upload-component"),
  },
  mounted() {
    this.fetchStudio();
    this.loadConfiguration();
  },

  computed: {
    ...mapState("inscriptions", ["studioState"]),
    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    center_image() {
      console.log(this.studio);
      if (this.studio && this.studio.user) {
        let s = this.studio.user.setting.find((s) => s.key === "center_image");
        if (s) return s.url;
      }
      return require("@/assets/logo.png");
    },
  },
  data() {
    return {
      settings: ["center_image_mark", "center_image_align"],
      center_image_mark: null,
      center_image_align: null,
      bg_file: [],
      center_file: [],
    };
  },
  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),

    ...mapActions("studios", ["getStudioByToken"]),
    fetchStudio() {
      this.getStudioByToken(this.$store.state.auth.user.studio.token).then(
        (response) => {
          this.studio = response;
        }
      );
    },
    loadConfiguration() {
      this.settings.forEach((key) => {
        this[key] = this.$store.getters["auth/getSetting"](key);
      });
    },
    update() {
      let count = 0;
      this.settings.forEach((key) => {
        this.settingUpdate({ key, value: this[key] }).then(() => {
          count++;
          this.$alert(this.$t("save_ok"));
          if (count === this.settings.length) {
            this.fetchStudio();
            this.loadConfiguration();
          }
        });
      });
    },
    ...mapActions("auth", ["imageForm"]),

    inputBGFile(newFile, oldFile) {
      console.log("BG");
      if (this.bg_file.length)
        this.$confirm(this.$t("studios.preview.change_bg_image"))
          .then(() => {
            let formData = new FormData();
            formData.append("photo", newFile.file);
            formData.append("key", "background_image");
            this.imageForm(formData).then((response) => {
              this.fetchStudio();
            });
          })
          .finally(() => {
            this.bg_file = [];
          });
    },
    inputCenterFile(newFile, oldFile) {
      console.log("CENTER");
      if (this.center_file.length)
        this.$confirm(this.$t("studios.preview.change_center_image"))
          .then(() => {
            let formData = new FormData();
            formData.append("photo", newFile.file);
            formData.append("key", "center_image");
            this.imageForm(formData).then((response) => {
              this.fetchStudio();
            });
          })
          .finally(() => {
            this.center_file = [];
          });
    },
  },
};
</script>

<style scoped lang="sass">
.selected
  border: 1px solid var(--v-primary-base)
.v-card__title
  text-align: center
  display: block
  width: 100%
  white-space: nowrap
.file-uploads
  height: 100px
  width: 100%
  border-radius: 10px
  border: solid var(--v-primary-base) 1px
  text-align: center
  div
    height: 100%
    display: grid
    align-items: center
</style>